<template>
  <v-container class="app-page">
    <div v-if="!loading">
      <v-row class="mb-2 px-4">
        <v-col>
          <h4 class="white--text"></h4>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <v-btn class="mr-2" small text color="white" @click="sheet = true">
            Descargar en PDF
            <v-icon right medium>cloud_download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card class="pa-6 mb-6">
        <v-card-title class="detalle-titulo pa-0">
          {{ liquidacion.DescTipoLiq }} - {{ liquidacion.MesDeLiq | gnsDate }}
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"              
              >
                <v-btn
                  small
                  rounded
                  elevation="0"
                  dark
                  :color="liquidacionEstadoColor"
                  right
                  @click="openModalEstado()"
                >
                  {{ liquidacionEstado }}
                  <v-icon v-if="liquidacion.idEstEncUsu=='4'" right dark x-small> mdi-open-in-new </v-icon>
                </v-btn>
              </div>
            </template>
            <span>Liquidación {{ liquidacionEstado }}</span>
          </v-tooltip> 
        </v-card-title>

        <v-row class="mt-6" justify="center">
          <v-expansion-panels accordion focusable>
            <v-expansion-panel class="panel-haberes">
              <v-expansion-panel-header color="haberes">
                <strong>Haberes</strong>
                <h4 class="text--danger text-right">
                  $ {{ liquidacion.Haberes | format_money }}
                </h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                  dense
                  class="detalle-table detalle-table-haberes"
                  widths
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left pr-0 pl-3">Nro</th>
                        <th class="text-left pr-0 pl-3">Concepto</th>
                        <th class="text-left pr-0 pl-3">Detalle</th>
                        <th class="text-left px-0"></th>
                        <th class="text-right pl-0 pr-3">Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in haberes" :key="item.id_Dato">
                        <td class="pr-0 pl-3">
                          <span>{{ item.id_Concepto }}</span>
                        </td>
                        <td class="pr-0 pl-3">
                          <span
                            v-html="item.Descripcion"
                            class="d-block text-truncate span-width"
                          ></span>
                        </td>
                        <td class="pr-0 pl-3">
                          <span class="d-block text-truncate span-width">{{
                            item.Detalle
                          }}</span>
                          <span
                            v-if="item.DescAgrupa && item.Detalle"
                            class="text-caption mb-0 grey--text"
                            >{{ item.DescAgrupa }}</span>
                        </td>
                        <td class="px-0 d-flex justify-center align-center">
                          <v-btn
                            v-if="item.Forma == 'ZF' || item.Forma == 'SQL' || item.Forma == 'RBD'"
                            class="detalle-icon pa-2"
                            dark
                            x-small
                            icon
                            @click="download(item)"
                          >
                            <v-img
                              src="@/assets/app-page/icon-lupa.svg"
                            ></v-img>
                          </v-btn>
                        </td>
                        <td class="text-right pl-0 pr-3">
                          {{ item.Monto | format_money }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5"></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="5" class="px-3">
                          <div class="d-flex justify-space-between">
                            <span>
                              <div v-if="otros.length >= 1">
                                Otros: $
                                {{ liquidacion.HaberesAD | format_money }}
                              </div>
                            </span>
                            <span class="text-right font-weight-bold">
                              TOTAL: $ {{ liquidacion.Haberes | format_money }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-descuentos">
              <v-expansion-panel-header color="descuentos">
                <strong>Descuentos</strong>
                <template v-slot:actions>
                  <h4 class="text--danger">
                    $ {{ liquidacion.Descuentos | format_money }}
                  </h4>
                </template>
                <div class="down-arrows">
                  <img src="@/assets/liquidaciones/arrow.svg" />
                  <img src="@/assets/liquidaciones/arrow.svg" />
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                  dense
                  class="detalle-table detalle-table-descuentos"
                  widths
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left pr-0 pl-3">Nro</th>
                        <th class="text-left pr-0 pl-3">Concepto</th>
                        <th class="text-left pr-0 pl-3">Detalle</th>
                        <th class="text-center px-0"></th>
                        <th class="text-right pl-0 pr-3">Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in descuentos"
                        :key="'descuento-' + item.id_Dato"
                      >
                        <td class="pr-0 pl-3">{{ item.id_Concepto }}</td>
                        <td class="pr-0 pl-3">
                          <span
                            v-html="item.Descripcion"
                            class="d-block text-truncate span-width"
                          ></span>
                        </td>
                        <td class="pr-0 pl-3">
                          <span class="d-block text-truncate span-width">{{
                            item.Detalle
                          }}</span>
                          <span
                            v-if="item.DescAgrupa && item.Detalle"
                            class="text-caption mb-0 grey--text"
                            >{{ item.DescAgrupa }}</span
                          >
                        </td>
                        <td class="px-0 d-flex justify-center align-center">
                          <v-btn
                            v-if="item.Forma == 'ZF' || item.Forma == 'SQL' || item.Forma == 'RBD'"
                            class="detalle-icon pa-2"
                            dark
                            x-small
                            icon
                            @click="download(item)"
                          >
                            <v-img
                              src="@/assets/app-page/icon-lupa.svg"
                            ></v-img>
                          </v-btn>
                        </td>
                        <td class="text-right">
                          {{ item.Monto | format_money }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5"></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="5" class="px-3">
                          <div class="d-flex justify-end">
                            <span class="text-right font-weight-bold">
                              TOTAL: $
                              {{ liquidacion.Descuentos | format_money }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>Líquido a Cobrar</strong>
                <template v-slot:actions>
                  <h4 class="text--danger">
                    $ {{ liquidacion.Liquido | format_money }}
                  </h4>
                </template>
              </v-expansion-panel-header>
              <!-- <v-expansion-panel-content></v-expansion-panel-content> -->
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card>
    </div>

    <v-dialog v-model="dialog" width="500" v-if="dialog && dialogContent">
      <v-card>
        <v-card-title class="headline grey lighten-2">Información</v-card-title>

        <v-card-text>{{ dialogContent }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-bottom-sheet v-model="sheet" v-if="liquidacion">
        <v-list>
          <v-subheader
            >{{ liquidacion.DescTipoLiq }}
            {{ liquidacion.MesDeLiq | gnsDate }}</v-subheader
          >
          <v-list-item
            v-for="item in liquidacion.lstDetCalc"
            :key="item.idDetaCalc"
            @click="download(item)"
          >
            <v-list-item-avatar>
              <v-avatar size="32px" tile>
                <v-icon left small>cloud_download</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.idTRep | concepto }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </div>
 
    <v-dialog v-if="liquidacion" v-model="modalEstado" width="520px">
      <v-card>
        <v-card-title class="headline">
          {{ modalEstadoTitle }}
          <v-spacer></v-spacer>
          <v-btn x-small icon right @click="modalEstado = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ modalEstadoSubtitle }}
        </v-card-subtitle>

        <v-card-text class="pb-0">
          <v-col cols="12" md="12" class="pa-0">
            <v-textarea
              outlined
              name="input-7-4"
              label="Motivo"
              :value="liquidacion.DescNoConforme"
              readonly
            ></v-textarea>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="modalEstado = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getDetalleLiquidacion } from "@/api/core";
import { gnsDate } from "@/filters/index";
import { formatMoney } from "@/utils/index";

const conceptos_map = {
  1: "Recibo PDF",
  2: "Detalle de Cálculo IRPF",
  3: "Salario Vacacional",
  4: "Aguinaldo",
  5: "Detalle de Cálculo de Reliquidacion",
  6: "Resolución DGI",
  7: "Información",
  0: "Personalizado",
};

export default {
  name: "detalle-liquidacion",
  data() {
    return {
      loading: true,
      sheet: false,
      haberes: [],
      descuentos: [],
      otros: [],
      totales: [],
      id: null,
      liquidacion: null,
      dialog: false,
      dialogContent: null,

      modalEstado: false,
      modalEstadoTitle: "",
      modalEstadoSubtitle: "",
    };
  },

  created() {
    this.id = this.$route.params && this.$route.params.id;
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", true);
    this.$store.commit("setToolbarTitle", "Detalle de Liquidación");
    this.$store.commit("startProcessing", {
      value: true,
      message: "Cargando...",
    });
    this.$store.commit("setActiveNav", 2);
    this.fetchLiquidacion();
  },

  filters: {
    gnsDate: function (value) {
      return gnsDate(value);
    },

    round: function (value) {
      return parseFloat(value).toFixed(2);
    },

    concepto: function (value) {
      return conceptos_map[value];
    },

    format_money: function (value) {
      return formatMoney(value);
    },
  },

  watch: {},

  computed: {
    liquidacionEstado() {
      if(this.liquidacion.idEstEncUsu == '4') {
        return 'Rechazada'
      }
      if(this.liquidacion.idEstEncUsu == '3') {
        return 'Aprobada'
      }
      return "Sin definir";
    },

    liquidacionEstadoColor() {
      if (this.liquidacion.idEstEncUsu == "3") {
        return "green";
      }
      if (this.liquidacion.idEstEncUsu == "4") {
        return "red";
      }
      return "green";
    },
  },

  methods: {
    openModalEstado() {
      if (this.liquidacion.idEstEncUsu == "4") {
        this.modalEstadoTitle =
          "Liquidacion: " +
          this.liquidacion.DescTipoLiq +
          " - " +
          gnsDate(this.liquidacion.MesDeLiq);
        this.modalEstadoSubtitle =
          "Registrar la No Conformidad de lo liquidado";
        this.modalEstado = true;
      }
    },

    async fetchLiquidacion() {
      this.loading = true;

      const data = await getDetalleLiquidacion(this.id);

      this.liquidacion = data.liquidacion;
      this.haberes = data.haberes.concat(data.otros);
      this.descuentos = data.descuentos;
      this.otros = data.otros;

      this.$store.commit("stopProcessing");

      this.loading = false;
    },

    async download(item) {
      if (item.Forma == "ZF" || item.Forma == "SQL" || item.Forma == 'RBD') {
        this.$router.push({
          name: "detalle_calculo",
          params: { id: item.idDetaCalc },
        });
      }

      if (item.Forma == "DAT") {
        this.dialog = true;
        this.dialogContent = item.DatoRuta;
      }

      if (item.Forma == "URL") {
        window.open(item.DatoRuta, "_blank");
      }

      if (
        item.Forma != "ZF" &&
        item.Forma != "DAT" &&
        item.Forma != "URL" &&
        item.Forma != "RBD" && 
        item.Forma != "SQL"
      ) {
        this.$store.commit("setSnackBar", {
          message: "Tipo de documento no soportado para visualizar",
          show: true,
          color: "#cc0605",
          timeout: 10000,
        });
      }
    },

    async downloadPdf() {
      const details = Object.values(this.liquidacion.lstDetCalc);
      const detail = details.find((item) => item.idTRep == "1");
      this.$router.push({
        name: "detalle_calculo",
        params: { id: detail.idDetaCalc },
      });
      /*const details = Object.values(this.liquidacion.lstDetCalc)
        const detail = details.find(item => item.idTRep == '1')
        this.$store.commit('startProcessing', { 'value': true, 'message': 'Cargando...'})
        const data = await getDetalleCalculo(detail.idDetaCalc, 0)
        this.$store.commit('stopProcessing')
        window.open(data.url, "_blank")*/
    },
  },
};
</script>

<style>
.detalle-titulo {
  font-size: 1rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: #434343;
}
.v-expansion-panel-header {
  color: #434343;
}
.v-card {
  box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5) !important;
  border-radius: 20px 20px 0px 20px !important;
}
.v-data-table__wrapper {
  border-radius: 8px !important;
}
tfoot td {
  border-top: thin dashed rgba(0, 0, 0, 0.12);
}
.detalle-table {
  width: 100%;
}

.detalle-table .v-data-table__wrapper {
  border-radius: 0px !important;
}

.detalle-table tr th {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 24px;
  color: #8392a7;
}

.detalle-table-haberes tbody {
  background: rgba(160, 208, 99, 0.05);
}
.detalle-table-descuentos tbody {
  background: rgba(219, 16, 52, 0.05);
}

.span-width {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .detalle-table tbody tr td {
    font-size: 11px !important;
  }
  tfoot td span {
    font-size: 11px !important;
  }
  .app-page {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .span-width {
    max-width: 180px;
  }
}
@media only screen and (max-width: 550px) {
  .span-width {
    max-width: 150px;
  }
}
@media only screen and (max-width: 500px) {
  .span-width {
    max-width: 120px;
  }
}
@media only screen and (max-width: 400px) {
  .span-width {
    max-width: 80px;
  }
}
@media only screen and (max-width: 340px) {
  .span-width {
    max-width: 70px;
  }
}
@media only screen and (max-width: 300px) {
  .span-width {
    max-width: 50px;
  }
}

.detalle-table tfoot {
  background: rgba(53, 53, 53, 0.9);
}
.detalle-table tfoot tr td {
  font-family: Montserrat;
  font-style: normal;
  line-height: 24px;
  color: #ffffff;
}

.detalle-table .v-responsive__content {
  width: 12px !important;
}

.detalle-table table tbody tr:last-child {
  height: 100%;
}

.detalle-table table tbody tr:last-child td {
  height: 100%;
  display: table-cell;
  vertical-align: inherit;
}

.v-expansion-panel-header {
  padding: 0 1rem !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.panel-haberes .v-expansion-panel-header--active::before {
  background-color: green;
}
.panel-descuentos .v-expansion-panel-header--active::before {
  background-color: red;
}

.panel-haberes .v-expansion-panel-header__icon {
  position: absolute !important;
  top: 15px !important;
  left: calc(50% - 10px) !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}
</style>
